import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Biography from "../routes/ourStory/Biography";
import LandingSection from "../routes/ourStory/LandingSection";
import Timeline from "../routes/ourStory/Timeline";
import Vibe from "../routes/ourStory/Vibe";

const OurStory = () => (
  <Layout>
    <Seo title="Our Story" />
    <LandingSection />
    <Biography />
    <Timeline />
    <Vibe />
  </Layout>
);

export default OurStory;
