import React from "react";
import styled from "styled-components";
import { Header } from "../../components/library/Text";
import { ImageConstants } from "../../constants/Images";
import { getFileParts } from "../../utils/browser";

const { base, extension } = getFileParts(ImageConstants.SILHOUETTE);
const mobileBackground = `${base}@1200w.${extension}`;
const desktopBackground = `${base}@2100w.${extension}`;

const Section = styled.section`
  position: relative;

  ::after {
    content: "";
    height: calc(100% + 70px);
    width: 100%;
    position: absolute;
    top: -70px;
    left: 0;
    background-color: #c4c4c4;
    background: linear-gradient(0deg, rgba(7, 7, 7, 0.6), rgba(7, 7, 7, 0.6)),
      url(${desktopBackground}) 0 0 / cover no-repeat;
    background-position-y: 30%;
    z-index: -1;
  }

  @media screen and (max-width: 768px) {
    height: 522px;

    ::after {
      background: linear-gradient(0deg, rgba(7, 7, 7, 0.6), rgba(7, 7, 7, 0.6)),
        url(${mobileBackground}) 0 0 / cover no-repeat;
      background-position-x: 20%;
    }
  }
`;

const Content = styled.div`
  max-width: 768px;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    height: 100%;
  }
`;

const Information = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 195px 0;

  @media screen and (max-width: 768px) {
    padding: 75px 0;
    height: 100%;
  }
`;

const HeaderText = styled(Header)`
  margin-bottom: 1rem;

  @media screen and (max-width: 768px) {
    font-size: 40px;
    line-height: 48px;
    padding: 0 20px;
    margin-bottom: 2rem;
  }
`;

const SmallText = styled.div`
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #ffffff;
  padding: 0 20px;
`;

const LandingSection = () => (
  <Section>
    <Content>
      <Information>
        <HeaderText>Our Mission</HeaderText>
        <SmallText>Give shine to Black men, everyday, everywhere</SmallText>
      </Information>
    </Content>
  </Section>
);

export default LandingSection;
