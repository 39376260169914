import React from "react";
import { Col, Container, Row } from "reactstrap";
import styled from "styled-components";
import { BlockQuotes, SmallBlockQuotes, Wave } from "../../components/library/Icons";
import { MultiSizePicture } from "../../components/library/Images";

const Section = styled.div`
  max-width: 1230px;
  margin: 0 auto;
  padding: 0 15px;
`;

const WaveContainer = styled.div`
  width: 67px;
  margin: 145px auto;

  @media screen and (max-width: 768px) {
    margin: 3.75rem auto;
  }
`;

const SmallWave = styled(Wave)`
  display: block;
`;

const Wrapper = styled(Container)`
  padding: 0;
`;

const ContentRow = styled.div`
  margin-bottom: 100px;

  @media screen and (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

const TextCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const SecondTextCol = styled(Col)`
  @media screen and (max-width: 768px) {
    order: 2;
  }
`;

const SecondPictureCol = styled(Col)`
  /* changing the display because the image is skinny on desktop */
  @media screen and (min-width: 769px) {
    display: flex;
    justify-content: flex-end;
  }

  @media screen and (max-width: 768px) {
    order: 1;
  }
`;

const PictureBox = styled.div`
  width: 100%;
  max-height: 572px;
  max-width: 572px;

  @media screen and (max-width: 768px) {
    margin: 0 auto 20px auto;
    max-height: 343px;
    max-width: 343px;
  }
`;

const InlinePictureBox = styled(PictureBox)`
  @media screen and (min-width: 769px) {
    margin: 0 auto 100px auto;
  }
`;

const GIFBox = styled(PictureBox)`
  margin: 0 auto 100px auto;

  @media screen and (max-width: 768px) {
    margin: 0 auto 20px auto;
  }
`;

const Img = styled.img<{ $grayscale?: boolean }>`
  margin: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: top;
  filter: ${props => (props.$grayscale ? "grayscale(100%)" : undefined)};
`;

const TextContainer = styled.div`
  @media screen and (min-width: 769px) {
    max-width: 495px;
  }
`;

const Text = styled.p`
  font-size: 24px;
  line-height: 1.2;
  letter-spacing: 0.02em;

  :last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`;

const PrePictureText = styled.p`
  font-size: 24px;
  line-height: 1.2;
  letter-spacing: 0.02em;
  margin-bottom: 100px;

  @media screen and (max-width: 768px) {
    font-size: 16px;
    margin-bottom: 20px;
  }
`;

const PreQuoteText = styled(Text)`
  :last-child {
    margin-bottom: 60px;
  }
`;

const QuoteContainer = styled.div`
  position: relative;
  padding-left: 120px;

  @media screen and (max-width: 768px) {
    padding-left: 0;
  }
`;

const BlockAbsPos = styled.div`
  position: absolute;
  top: -60px;
  left: 0;
  z-index: -1;

  @media screen and (max-width: 768px) {
    top: -40px;
  }
`;

const BigQuotes = styled(BlockQuotes)`
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const SmallQuotes = styled(SmallBlockQuotes)`
  @media screen and (min-width: 769px) {
    display: none;
  }
`;

const QuoteText = styled.p`
  font-family: Quarto;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 1.2;
  color: #090c0e;

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }
`;

const Author = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 1.2;
  color: #84a2b4;

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`;

const FromBox = styled.div`
  margin-bottom: 87px;
`;

const From = styled(Text)`
  margin: 0;
  color: rgba(0, 0, 0, 0.6);
`;

const Signatures = styled.div`
  width: 100%;
  max-width: 895px;
  max-height: 250px;

  img {
    margin-left: -33px;
    max-width: 895px;
    max-height: 250px;
  }

  @media screen and (max-width: 768px) {
    img {
      margin-left: -3.7%;
    }
  }
`;

const Image = styled(MultiSizePicture)`
  img {
    margin: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: top;
  }
`;

const Biography = () => (
  <Section>
    <WaveContainer>
      <SmallWave />
      <SmallWave />
    </WaveContainer>
    <ContentRow>
      <Wrapper fluid>
        <Row>
          <Col md={6}>
            <PictureBox>
              <Image src="https://static.wavyformula.com/images/backflip.jpg" />
            </PictureBox>
          </Col>
          <TextCol md={6}>
            <TextContainer>
              <Text>
                We created Wavy Blue with one simple mission in mind: to give shine to Black men
                that make waves everyday, everywhere. We are driven to create products, services,
                and experiences that our people deeply deserve, and rather than waiting for others
                to do it for us, we’ve built Wavy Blue hand in hand with each of you. Above all else
                we care about illuminating the stories that haven’t been told and building products
                that help our community live a better life.
              </Text>
            </TextContainer>
          </TextCol>
        </Row>
      </Wrapper>
    </ContentRow>
    <ContentRow>
      <Wrapper fluid>
        <Row>
          <SecondTextCol md={6}>
            <TextContainer>
              <PreQuoteText>
                Like many great stories, ours started long before us, with people that came before
                us, who laid the foundation for us to thrive. Ancestors who sacrificed, who toiled,
                who fought, and who died, so that we could live a better life; the ones who
                exemplified Khadijah Queen’s assertion:
              </PreQuoteText>
            </TextContainer>
          </SecondTextCol>
          <SecondPictureCol md={6}>
            <PictureBox>
              <Image src="https://static.wavyformula.com/images/joice-kelly-square.jpeg" />
            </PictureBox>
          </SecondPictureCol>
        </Row>
      </Wrapper>
    </ContentRow>
    <ContentRow>
      <QuoteContainer>
        <BlockAbsPos>
          <BigQuotes />
          <SmallQuotes />
        </BlockAbsPos>
        <QuoteText>
          Black people have defined and still define themselves for themselves amid roses and
          dandelions, cardinals and violets, the blues of music and police uniforms, prayer and
          swagger, Kehinde Wiley paintings and too many funerals, the streets of bleak cities and
          the fraught histories of a 'kill or be killed / nation.
        </QuoteText>
        <Author>- Khadijah Queen</Author>
      </QuoteContainer>
    </ContentRow>
    <ContentRow>
      <GIFBox>
        <Img src="https://static.wavyformula.com/images/toss-jar.gif" />
      </GIFBox>
      <Text>
        We hope that Wavy Blue - all that it is and all that it will be - can represent a space{" "}
        <i>for us by us</i> for joy, uplift, and empowerment.
      </Text>
      <PrePictureText>
        “Why start with personal care?” you might ask. For us, the answer is simple: we were tired
        of the same old thing - products built for our fathers and our fathers’ fathers that didn’t
        give us the feeling that we deserve. So we got to work and launched our first thing: the
        Curl & Coil Balm, a simple and exceptional leave-in conditioner designed to effortlessly
        hydrate, style, and condition the hair on your head and beard; and perfect for any routine.
      </PrePictureText>
      <InlinePictureBox>
        <Image
          src="https://static.wavyformula.com/images/stoic-square.jpg"
          alt="Photo by Julian Myles on Unsplash"
        />
      </InlinePictureBox>
      <Text>
        All of our products are designed with Black hair ready ingredients, to make way of the small
        things, so you can focus on the important ones - making waves everyday, everywhere.
      </Text>
    </ContentRow>
    <FromBox>
      <From style={{ marginBottom: "1rem" }}>Cam & Mckervin</From>
      <From>
        <i>Founders of Wavy Blue</i>
      </From>
      <Signatures>
        <Image
          src="https://static.wavyformula.com/images/founders-signatures.png"
          alt="Founders' signatures"
        />
      </Signatures>
    </FromBox>
  </Section>
);

export default Biography;
