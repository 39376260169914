import React from "react";
import styled from "styled-components";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { SubHeader, Text as BaseText } from "../../components/library/Text";

const Section = styled.div`
  text-align: center;
  padding: 105px 15px;

  @media screen and (max-width: 768px) {
    padding: 62px 15px;
  }
`;

const Wrapper = styled.div`
  max-width: 667px;
  margin: 0 auto;
`;

const Text = styled(BaseText)`
  color: #0e0e0e;
  margin-bottom: 50px;
`;

const Card = styled.div`
  width: 100%;
  max-width: 509px;
  height: 265px;
  margin: 0 auto;
  padding: 52px 130px;

  background: white;
  box-shadow: 0px 6px 64px -20px rgba(0, 0, 0, 0.14);
  border-radius: 24px;

  @media screen and (max-width: 768px) {
    padding: 52px 15px;
  }
`;

const CardText = styled(BaseText)`
  color: #0e0e0e;
  margin-bottom: 20px;
`;

const ListenNow = styled(OutboundLink).attrs(() => ({
  target: "_blank",
  rel: "noreferrer"
}))`
  display: block;
  background: #090c0e;
  border-radius: 50px;
  height: 49px;
  line-height: 49px;
  border: none;
  font-weight: bold;
  font-size: 15px;
  color: white;
  padding: 0 22.5px;
  font-family: "Cera Round Pro", "Gill Sans", georgia, serif;

  :hover {
    color: white;
    text-decoration: none;
  }
`;

const Vibe = () => (
  <Section>
    <Wrapper>
      <SubHeader>Vibe With Us</SubHeader>
      <Text>
        Every month we release playlists with music we vibe to. We mixed some of our current
        favorites old and new. Recommend listening with a cold beverage in hand.
      </Text>
      <Card>
        <CardText>MONTHLY MIX #3</CardText>
        <CardText>Summer classics and some new Nas</CardText>
        <ListenNow href="https://music.getwavyblue.com">Listen Now</ListenNow>
      </Card>
    </Wrapper>
  </Section>
);

export default Vibe;
