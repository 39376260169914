import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import styled from "styled-components";
import { Button } from "../../components/library/Button";
import { MultiSizeImage } from "../../components/library/Images";
import { SubHeader } from "../../components/library/Text";
import useMobile from "../../hooks/useMobile";

interface ItemComponentProps {
  index: number;
  showLine: boolean;
  img: string;
  date: string;
  isMobile: boolean;
  header?: string;
  content?: React.ReactNode;
}

const Section = styled.div`
  background-color: #f6fcff;
  padding: 87px 102.5px 231px;

  @media screen and (max-width: 768px) {
    padding: 87px 0 231px;
  }
`;

const Header = styled(SubHeader)`
  text-align: center;
  margin-bottom: 95px;
  padding: 0 15px;
`;

const TimelineButton = styled(Button)<{ onClick?: () => void }>`
  display: block;
  height: 101px;
  width: 366px;
  margin: 0 auto;
  color: #090c0e;
  background-color: #def0fb;
  padding: 38px 68.5px;

  @media screen and (max-width: 768px) {
    width: auto;
    padding: 38px;
  }
`;

const TimelineContainer = styled.div`
  margin-bottom: 95px;
`;

const Desktop = styled.div`
  position: relative;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const Mobile = styled.div`
  position: relative;
  padding: 0 32px;

  @media screen and (min-width: 769px) {
    display: none;
  }
`;

const ItemContainer = styled(Container)`
  position: relative;
  margin-bottom: 199px;

  :last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: 768px) {
    padding: 0;
    margin-bottom: 20px;

    :last-child {
      margin-bottom: 0;
    }
  }
`;

const LeftCol = styled(Col)`
  display: flex;
  padding-left: 0;

  @media screen and (min-width: 769px) {
    padding-right: 45px;
    justify-content: flex-end;
    text-align: right;
  }
`;

const RightCol = styled(Col)`
  padding-left: 45px;
  padding-right: 0;

  @media screen and (max-width: 768px) {
    padding-left: 0;
  }
`;

const TextBox = styled.div`
  position: relative;
  padding-top: 16px;
`;

const PictureBox = styled(MultiSizeImage)`
  width: 100%;
  height: 100%;
  max-height: 572px;
  max-width: 572px;

  @media screen and (max-width: 767px) {
    min-height: 250px;
    min-width: 250px;
    max-height: 350px;
    max-width: 350px;
  }

  @media screen and (width: 768px) {
    width: 250px;
    height: 250px;
  }
`;

const DateText = styled.div`
  font-size: 28px;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: 0.02em;
  color: #bce0f6;

  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
`;

const TitleText = styled.div`
  font-size: 36px;
  line-height: 1.2;
  letter-spacing: 0.02em;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 22px;

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }
`;

const Bullet = styled.div`
  position: absolute;
  top: 22px;
  left: 50%;
  border-radius: 50%;
  width: 23px;
  height: 23px;
  background-color: #bce0f6;

  @media screen and (max-width: 768px) {
    top: 27.5px;
    left: -20.5px;
    width: 9px;
    height: 9px;
  }

  @media screen and (min-width: 769px) {
    transform: translateX(-50%);
  }
`;

const VerticalLine = styled.div`
  position: absolute;
  left: 50%;
  top: 22px;
  border-left: 3px solid #bce0f6;

  @media screen and (max-width: 768px) {
    border-left: 2px solid #bce0f6;
    top: 36.5px;
    left: -17px;
  }

  @media screen and (min-width: 769px) {
    transform: translateX(-50%);
  }
`;

const Description = styled.div`
  @media screen and (max-width: 768px) {
    margin-bottom: 22px;
  }
`;

const Item = React.memo((props: ItemComponentProps) => {
  const { index, img, content, date, header, showLine, isMobile } = props;
  const isEven = index % 2 === 0;

  const TC = (
    <TextBox>
      {isMobile ? <Bullet /> : null}
      {showLine && isMobile ? <VerticalLine className="line" /> : null}
      <DateText>{date}</DateText>
      {!!header ? <TitleText>{header}</TitleText> : null}
      <Description>{content}</Description>
    </TextBox>
  );
  const IMG = <PictureBox src={img} />;

  return (
    <ItemContainer fluid className="timeline-item">
      <Row noGutters={isMobile}>
        {isMobile ? (
          <>
            <LeftCol md={6}>{TC}</LeftCol>
            <RightCol md={6}>{IMG}</RightCol>
          </>
        ) : (
          <>
            <LeftCol md={6}>{isEven ? IMG : TC}</LeftCol>
            <Bullet />
            {showLine ? <VerticalLine className="line" /> : null}
            <RightCol md={6}>{isEven ? TC : IMG}</RightCol>
          </>
        )}
      </Row>
    </ItemContainer>
  );
});

const items = [
  {
    date: "1897",
    header: "It all started when...",
    img: "https://static.wavyformula.com/images/cam-ancestor.jpg",
    content:
      "My paternal great-grandfather, Scott, paved the way as a voting rights activist and an entrepreneur. He owned a business that doubled as both a general store and a restaurant. Although his life ended tragically in 1970, his penchant for entrepreneurship inspired me to work together with Mckervin to build Wavy Blue."
  },
  {
    date: "1990s",
    header: "We're '90s kids",
    img: "https://static.wavyformula.com/images/1990s.png",
    content:
      "We're '90s kids at heart. We grew up in the era of Static Shock, All That, Boondocks and Allen Iverson on TV, and OutKast, Jay-Z, and Lil Wayne on constant repeat."
  },
  {
    date: "2011",
    img: "https://static.wavyformula.com/images/swerve-cam.jpg",
    content:
      "Mckervin and I became close friends in undergrad. Experiencing the challenges of student life together helped ingrain some important life lessons - perseverance, problem-solving, and embracing our inner 'creative'."
  },
  {
    date: "2019",
    header: "Wavy Blue was first born",
    img: "https://static.wavyformula.com/images/original-design.jpg",
    content:
      "Although we aren’t artists, we began with sketching - laying the foundation for what Wavy Blue would one day look like."
  },
  {
    date: "2020",
    header: "Launched It!",
    img: "https://static.wavyformula.com/images/holding-jar.jpg",
    content:
      "We finally brought Wavy Blue to life - and launched with the support of each and every one of you!"
  },
  {
    date: "Today",
    header: "Sky’s the limit",
    img: "https://static.wavyformula.com/images/Today.png",
    content: "This is just the beginning - stay tuned for much more."
  }
];

const Timeline = () => {
  const isMobile = useMobile();
  const desktopRef = useRef<HTMLDivElement>(null);
  const mobileRef = useRef<HTMLDivElement>(null);
  const [showTimeline, setShowTimeline] = useState(false);

  const calculateLineHeight = useCallback(() => {
    // check if mobile raw
    const isSmall = window.innerWidth <= 768;

    // get height
    const offset = isSmall ? 12 : 200;
    const ref = isSmall ? mobileRef.current : desktopRef.current;
    if (ref) {
      Array.from(ref.children).forEach(el => {
        const node = el as HTMLElement;
        const line = Array.from(node.getElementsByClassName("line"))[0] as HTMLDivElement;
        if (line) {
          line.style.height = `${node.offsetHeight + offset}px`;
        }
      });
    }
  }, []);

  useLayoutEffect(calculateLineHeight, [showTimeline]);

  useLayoutEffect(() => {
    setShowTimeline(window.innerWidth <= 768);
    window.addEventListener("resize", calculateLineHeight);
    return () => {
      window.removeEventListener("resize", calculateLineHeight);
    };
  }, []);

  return (
    <Section>
      <Header>How it All Started</Header>
      {showTimeline ? null : (
        <TimelineButton onClick={() => setShowTimeline(true)}>Unravel Our Journey</TimelineButton>
      )}
      <TimelineContainer style={{ display: showTimeline ? undefined : "none" }}>
        <Desktop ref={desktopRef}>
          {items.map((item, i) => (
            <Item
              key={`item${i}`}
              index={i}
              isMobile={isMobile}
              showLine={i !== items.length - 1}
              {...item}
            />
          ))}
        </Desktop>
        <Mobile ref={mobileRef}>
          {items.map((item, i) => (
            <Item
              key={`item${i}`}
              index={i}
              isMobile={isMobile}
              showLine={i !== items.length - 1}
              {...item}
            />
          ))}
        </Mobile>
      </TimelineContainer>
      {showTimeline ? (
        <TimelineButton onClick={() => setShowTimeline(false)}>Hide Journey</TimelineButton>
      ) : null}
    </Section>
  );
};

export default Timeline;
